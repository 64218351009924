html {
  height: 100%;
  color: #000;
  font-family: 'Overpass-Regular', sans-serif;
  font-size: 0.8rem;
}

@media (max-width: 540px) {
  html {
    font-size: 0.7rem;
  }
}

body,
#root {
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

input,
textarea,
select {
  font-family: inherit;
}

a {
  color: #030725;
  cursor: pointer;
  text-decoration: none;
}

/* stylelint-disable */

/* latin-ext */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  src: local('Overpass Regular'), local('Overpass-Regular'),
    url(assets/fonts/overpass-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  src: local('Overpass Regular'), local('Overpass-Regular'),
    url(assets/fonts/overpass-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* vietnamese */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Space Mono'), local('SpaceMono-Regular'),
    url(assets/fonts/spacemono-vietnamese.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Space Mono'), local('SpaceMono-Regular'),
    url(assets/fonts/spacemono-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Space Mono'), local('SpaceMono-Regular'),
    url(assets/fonts/spacemono-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* vietnamese */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Space Mono Bold'), local('SpaceMono-Bold'),
    url(assets/fonts/spacemono-bold-vietnamese.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Space Mono Bold'), local('SpaceMono-Bold'),
    url(assets/fonts/spacemono-bold-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Space Mono Bold'), local('SpaceMono-Bold'),
    url(assets/fonts/spacemono-bold-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* stylelint-enable */

.Spacer {
  display: flex;
  flex-grow: 1;
}

/* REACT-TABLE Custom styles */
.ReactTable.-striped .rt-tr.-odd {
  background: #f5f7fa;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border: none;
}

.ReactTable .rt-tbody .rt-td {
  border: none;
}
